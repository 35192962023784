import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapPageElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeKGL0pAAAAAER4SlDjXSv8TPDTlovLJhJ7ZFTi">
        {element}
    </GoogleReCaptchaProvider>
  )
}
